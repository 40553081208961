.hero-card {
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
}

.hero-card div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.hero-card div:nth-child(1) {
    width: 100%;
    height: 100%;
}

.hero-card div:nth-child(2) {
    position: absolute;
    bottom: 0;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    background: rgb(123, 117, 76);
    width: 100%;
    height: 50px;
    display: flex;
    place-content: center;
    place-items: center;
    font-weight: bold;
    clip-path: polygon(7% 8%, 14% 18%, 100% 18%, 100% 100%, 0 100%, 0 19%);
    border-radius: 5px;
}

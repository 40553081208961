.petcard {
    position: relative;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    max-width: 800px;
    margin: auto;
    margin-bottom: 3rem;
}

.petcard > div:nth-child(1) {
    width: 150px;
    height: 150px;
}

.petcard > div:nth-child(1) > img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.petcard > div:nth-child(3) {
    margin-left: auto;
    display: flex;
    /* background: red; */
    flex-direction: column;
    justify-content: space-around;
    margin-right: 1rem;
}

.petcard p {
    margin: 0;
}
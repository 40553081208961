@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Montserrat:wght@300&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  width: full;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

nav a {
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
nav a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
nav a:hover:after { 
  width: 100%; 
  left: 0; 
}

#login {
  border: 1px solid black;
}

#login a::after {
  width: 0 !important;
}


.container {
  min-height: 100vh;
}

@media screen and (max-width:600px) {
    nav a {
      font-size: 10px;
      padding:5px;
      font-weight: bold;
    }

    .mobile-hidden {
      display: none;
    }
}


h1, h2, h3, h4 {
  font-family: 'Inter', sans-serif;
}

p {
  font-family: 'Montserrat',sans-serif;
}

@font-face {
    font-family: poiret;
    src: url('../../fonts/Poiret_One/PoiretOne-Regular.ttf');
}

.release-container {
    /* display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px; */

}

.release-container * {
    font-family: poiret, sans-serif;
}

.release {
    background: white;
}

.release form h2 {
    font-size: 4rem;
    margin-left: 2rem;
}

.release button {
    margin-left: 2rem;
    padding: 1rem 3rem;
    font-size: 1rem;
    font-weight: bold;
    background: none;
    border: 1px solid black;
    margin-bottom: 3rem;
}



.release input {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    min-width: 300px;
    height: 50px;
    padding-left: 20px;
    color: black;
    font-weight: bolder;
}

.release label {
    /* background: red; */
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-size: 2rem;
}



.release .col {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.release .row {
    display: grid;
    margin-bottom: 2rem;
    grid-template-columns: 1fr 1fr;
}

.release .row img {
    width: 100px;
    height: 100px;
    margin: 1rem;
    border-radius: 10px;
    object-fit: cover;

}

.release .row div {
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:700px) {
    .release .row {
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }

    .release .row div {
        margin-bottom: 2rem;
    }
}
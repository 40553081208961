.servicecard{
    max-width: 300px;
    min-width: 200px;
    aspect-ratio: 1/1;
    background: rgba(92, 92, 0,0.3);
    border-radius: 15px;
    margin: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
}

.servicecard svg{
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
}

.servicecard span {
    font-weight: bold;
}
.search {
    /* background: red; */
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: auto;
    min-width: 400px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 2rem;


}

.search input {
    flex-grow: 1;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border: none;
    border-right: 1px solid rgba(0,0,0,0.2);
    padding: 10px;
}

.search svg {
    width: 30px;
    height: 30px;
    margin:auto 5px;
}

.petcontainer {
    padding: 1rem;
}